export const environment = {
  production: true,
  googleMapsApiKey: 'AIzaSyDYxqV4YobabV-Owr72OmNmocz_720eIdc',
  apiUrl: 'https://api.evland.tech',
  // apiUrl: 'http://localhost:2000',
  wsUrl: 'wss://api.evland.tech/ws',
  // wsUrl: 'ws://localhost:2000/graphql',
  recaptchaSiteKey: '6Le8tyAoAAAAAGUQQyQuBTBn7KpQl-RGT-iQVWHS',
  telegramBotName: 'random_awesome_bot',
};
