<button type="submit" [class.is-loading]="isLoading()" class="submit-button btn btn-lg btn-primary">

  @if (isLoading()) {

    <div class="loader-container d-flex justify-content-center align-items-center">
      <evland-loader color="#fff" size="20"></evland-loader>
    </div>

  }

  <ng-content></ng-content>

</button>
