import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  Optional,
  Signal,
  SkipSelf
} from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { LoaderComponent } from '@shared/ui-components/loader/loader.component';

@Component({
  selector: 'evland-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: [ './submit-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LoaderComponent,
  ],
})
export class SubmitButtonComponent {
  color = input<'primary' | 'secondary' | 'success' | 'warning' | 'danger'>('primary');
  isLoading = input<boolean>();
  checkPristine = input<boolean>(true);
  disabled = input<boolean>();

  form: UntypedFormGroup;
  isDisabled: Signal<boolean>;

  constructor(
    @Optional() @SkipSelf() private formDirective: FormGroupDirective,
  ) {
    this.isDisabled = computed(() => {
      const disabled = this.disabled();
      const checkPristine = this.checkPristine();
      const isLoading = this.isLoading();

      return !!disabled || (checkPristine && this.form?.pristine) || !this.form?.valid || !!isLoading;
    });
  }

  ngOnInit(): void {
    this.form = this.formDirective?.form;
  }
}
